/**
 * Takes a string of html and returns an object containing the html with added ids to headings
 * and an array of objects containing the headings, their tags and the assigned ids
 * @param rawHtml
 * @returns {{rawHtml, tocEntries: *}}
 */
export function getTocFromHtml (rawHtml) {
  // get all heading elements in val.content - also contains element-text
  const headings = rawHtml.match(/<h[1-6]>(.*?)<\/h[1-6]>/g)
  if (!headings) {
    return {
      rawHtml: "",
      tocEntries: []
    }
  }

  // create an array of objects, each containing the heading text without tag and the tag itself
  const tocEntries = headings.map((heading) => {
    const headingTag = heading.match(/<h[1-6]>/g)[0]
    const headingText = heading.match(/<h[1-6]>(.*?)<\/h[1-6]>/)[1]
    return {
      tag: headingTag,
      text: headingText,
      id: ""
    }
  })
  // loop through headingsArray and add an id to each heading in value.content
  tocEntries.forEach((heading) => {
    // replace spaces with dashes, quotation marks and make lowercase
    const headingId = heading.text
      .replace(/ /g, "-")
      .replace(/"/g, "")
      .toLowerCase()
    heading.id = headingId

    rawHtml = rawHtml.replace(
      heading.tag + heading.text,
      heading.tag.replace(">", ' id="' + headingId + '">') + heading.text
    )
  })

  return {
    rawHtml,
    tocEntries
  }
}

/**
 * Resolve relative URLs in HTML content to absolute URLs containing the current host
 * Especially useful for images
 * @param rawHtml
 * @returns {string}
 */
export function resolveRelativeUrls (rawHtml) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(rawHtml, "text/html")

  const images = doc.querySelectorAll("img")
  images.forEach((image) => {
    let src = image.getAttribute("src")
    console.log(src)
    if (src && src.startsWith("/")) {
      src = process.env.VUE_APP_STRAPI_URL + src
    }
    image.setAttribute("src", src)
  })
  return doc.body.innerHTML
}
