/** Static content mixin */
<script>
import {
  getTocFromHtml,
  resolveRelativeUrls
} from "@/helpers/content-utilities"

export default {
  name: "StaticContent",
  data () {
    return {
      content: {},
      tocItems: []
    }
  },
  mounted () {
    if (!this.content || !this.content.content) return

    // Resolve relative URLS
    this.content.content = resolveRelativeUrls(this.content.content)

    if (this.content.generateToc) {
      const { rawHtml, tocEntries } = getTocFromHtml(this.content.content)
      this.content.content = rawHtml
      this.tocItems = tocEntries
    }
  },
  watch: {
    content: {
      handler (content) {
        if (!this.content || !this.content.content) return

        // Resolve relative URLS
        this.content.content = resolveRelativeUrls(this.content.content)

        if (this.content.generateToc) {
          const { rawHtml, tocEntries } = getTocFromHtml(content.content)
          this.content.content = rawHtml
          this.tocItems = tocEntries
        }
      }
    }
  }
}
</script>
