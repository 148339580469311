<template>
  <b-container>
    <h1>{{ content.title }}</h1>
    <div class="cms-content" v-html="content.content"></div>
  </b-container>
</template>

<script>
import StaticContent from "@/mixins/StaticContent.vue"
import { gql } from "apollo-boost"

export default {
  name: "Sources",
  mixins: [StaticContent],
  apollo: {
    content: {
      query: gql`
        query getSourceData {
          dataSource {
            data {
              attributes {
                title
                content
              }
            }
          }
        }
      `,
      update: (data) => data.dataSource.data.attributes
    }
  }
}
</script>
